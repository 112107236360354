import React from "react";
import styles from "./customPlan.module.css";

const CustomPlan = () => {
  const handleClickScroll = (idName) => {
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${styles.header}`);
        const headerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - (headerHeight + 80);

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };
  return (
    <div className={styles.customPlanContainer}>
      <div className={styles.PlanNameContainer}>
        <p className={styles.PlanName}> Enterprise</p>
      </div>
      <div className={styles.pricingContainer}>
        <p className={styles.pricingdetails}>
          {" "}
          Craft a personalized experience with our custom plan. Designed for
          your unique goals.
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.button}
          onClick={() => handleClickScroll("team")}
        >
          Contact us
        </button>
      </div>
      <div className="d-flex justify-content-center">
        <div
          style={{ background: "#D9D9D9", width: "85%", height: "1px" }}
        ></div>
      </div>
      
    </div>
  );
};

export default CustomPlan;
