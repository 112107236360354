import React from "react";
import classes from "./marketing.module.css";
import { MarketingData } from "../../data/marketingData/MarketingData";
import { Button } from "react-bootstrap";

const Marketing = () => {
  return (
    <div className={classes.container} id="marketing">
      <div className={classes.subContainer}>
        <h2 className={classes.title}>What's New</h2>
        <h2 className={classes.subTitle}>Catch up on our latest blog posts</h2>
        <div className={`${classes.scrollableContainer}`}>
          <div className={classes.cardsContainer}>
            {MarketingData.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.anchorTag}
                >
                  <div className={`${classes.marketingCard}`}>
                    <div className={classes.marketingImage}>
                      <img
                        src={item.image}
                        alt="marketing"
                        title="Marketing Card"
                        className={classes.marketingImage}
                      />
                    </div>
                    <p className={classes.publishBy}>Publish By: {item.publishBy}</p>
                    <h3 className={classes.publishTitle}>
                      {item.publishTitle}
                    </h3>
                    <h3 className={`${classes.publishDescription}`}>
                      {item.publishDescription}
                    </h3>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${classes.button}`}
                    >
                      View More
                    </a>
                    {/* <h6 className={`${classes.button}`}  >View More</h6> */}
                    {/* <div className={`${classes.badgesContainer} gap-2`}>
                      <p
                        className={`${classes.badges} rounded-pill`}
                        style={{
                          background:
                            item.badges === "Skalebot Pro" || "Management"
                              ? "#F8F9FC"
                              : "#F9F5FF",
                          color:
                            item.badges === "Skalebot Pro" || "Management"
                              ? "#363F72"
                              : "#707070"
                        }}
                      >
                        {item.badges[0]}
                      </p>
                      <p
                        className={`${classes.badges} rounded-pill`}
                        style={{
                          background:
                            item.badges === "Skalebot Pro" || "Management"
                              ? "#F8F9FC"
                              : "#F9F5FF",
                          color:
                            item.badges === "Skalebot Pro" || "Management"
                              ? "#363F72"
                              : "#707070"
                        }}
                      >
                        {item.badges[1]}
                      </p>
                    </div> */}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <a
          className={classes.buttonContainer}
          href="https://blogs.skalebot.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h6 className={classes.buttonText}>View More</h6>
        </a>
      </div>
    </div>
  );
};

export default Marketing;
