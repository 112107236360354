import { ReactComponent as RupeeSvg } from "../../images/paymentPlan/rupeeWhite.svg";
import { ReactComponent as DollarSvg } from "../../images/paymentPlan/dollarWhite.svg";

export const StandardPlanData = {
    IN: [
        "2 FREE user License",
        "Sales chat box",
        "Whatsapp marketing analytics and scheduling",
        "Ticket system (Using Round Robin)",
        "Mobile App (Available on Android & IOS)"
    ],
    otherCountry: [
        "2 FREE user License",
        "Sales chat box",
        "Whatsapp marketing analytics and scheduling",
        "Ticket system (Using Round Robin)",
        "Mobile App (Available on Android & IOS)"
    ]
};

export const amounts = {
    IN: "2499",
    otherCountry: 29
};

export const currencyIcons = {
    IN: RupeeSvg,
    otherCountry: DollarSvg
};

export const conversationDetails = {
    IN: "Conversation charges: As per Meta rates",
    otherCountry: "Conversation charges: As per Meta rates"
};

export const additionalUserLicenseDetails = {
    IN: "₹1199 /Month per user",
    otherCountry: "$15/Month per user"
};
